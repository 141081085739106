import { ref } from '@vue/composition-api'
import store from '@/store'

export const useShopRemoteData = () => {
  const points = ref([])
  const fetchPoints = (...args) => store.dispatch('point/fetchPoints', ...args)

  return {
    points,
    fetchPoints,
  }
}

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    page: 1,
    perPage: 6,
    isSortDirDesc: true,
    pointPurposes: [],
    userId: null,
    postStatuses: [],
  })

  const filterOptions = {
    perPage: [6, 9, 24, 72],
    pointPurposes: [],
    useId: null,
    postStatuses: [],
  }

  // Sorting
  const sortBy = ref({ text: 'Name', value: 'name' })
  const sortByOptions = [
    { text: 'Name', value: 'name' },
    { text: 'Estimated time of stay', value: 'estimated_time_of_stay' },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useShopUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalPoints = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalPoints,
  }
}
