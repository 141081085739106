<template>
  <div style="height: inherit">

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalPoints }} points found
              </div>
            </div>
            <div class="view-options d-flex">
              <!-- Per Page -->
              <b-col>
                <label>Show</label>
                <v-select
                  v-model="filters.perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="filterOptions.perPage"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>
              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Search Product"
              class="search-point"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Points -->
    <section :class="itemView">
      <b-card
        v-for="point in points"
        :key="point.id"
        class="ecommerce-card"
        no-body
        title="point.name"
      >
        <div class="item-img text-center">
          <b-link :to="{ name: 'apps-points-details', params: { id: point.id } }">
            <b-img-lazy
              :alt="`${point.name}-${point.id}`"
              fluid
              class="card-img-top"
              :src="point.main_image ? point.main_image.url: require('@/assets/images/pages/no-photo-available.png')"
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <b-card-text class="text-left">
            <b-badge
              pill
              :variant="`light-${resolvePointTypeBadgeVariant(point.point_type.slug)}`"
            >
              {{ point.point_type.title }}
            </b-badge>
            <b-badge
              pill
              variant="light-dark"
            >
              {{ point.point_purpose.title }}
            </b-badge>
          </b-card-text>
          <b-media no-body>
            <b-media-aside
              vertical-align="center"
              class="mr-50"
            >
              <b-avatar
                v-if="'avatar'in point.user"
                href="javascript:void(0)"
                size="24"
                :src="point.user.avatar"
              />
              <b-avatar
                v-if="!('avatar'in point.user)"
                button
                size="24"
                variant="dark"
                :text="getUserAvatarText(point.user.name)"
                class="align-baseline"
              />
            </b-media-aside>
            <b-media-body>
              <small class="text-muted mr-50">by</small>
              <small>
                <b-link class="text-body">{{ point.user.name }}</b-link>
              </small>
              <span class="text-muted ml-75 mr-50">|</span>
              <small class="text-muted">Jan 10, 2020</small>
            </b-media-body>
          </b-media>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{ name: 'apps-points-edit', params: { id: point.id } }"
            >
              {{ point.name }}
            </b-link>
            <!--            <b-card-text class="item-company">-->
            <!--              By <b-link class="ml-25">-->
            <!--                {{ point.brand }}-->
            <!--              </b-link>-->
            <!--            </b-card-text>-->
          </h6>
          <b-card-text class="item-description">
            {{ point.description }}
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <!--            <div class="item-cost">-->
            <!--              <h4 class="item-price">-->
            <!--                ${{ point.price }}-->
            <!--              </h4>-->
            <!--            </div>-->
          </div>
          <!--          <b-button-->
          <!--            variant="light"-->
          <!--            tag="a"-->
          <!--            class="btn-wishlist"-->
          <!--            @click="toggleProductInWishlist(point)"-->
          <!--          >-->
          <!--            <feather-icon-->
          <!--              icon="HeartIcon"-->
          <!--              class="mr-50"-->
          <!--              :class="{'text-danger': point.isInWishlist}"-->
          <!--            />-->
          <!--            <span>Wishlist</span>-->
          <!--          </b-button>-->
          <b-button
            v-if="ability.can('update', point)"
            variant="light"
            tag="a"
            class="btn-cart"
            @click="ability.can('update', point) ? handleEditPointActionClick(point) : null"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>{{ point.isInCart ? 'View In Cart' : 'Edit point' }}</span>
            <span class="post-status">
              <b-badge
                :variant="`light-${getPostStatusProperty(point.post_status, 'class')}`"
              >
                <feather-icon
                  :icon="getPostStatusProperty(point.post_status, 'icon')"
                  class="mr-25"
                />
                <span>{{ point.post_status }}</span>
              </b-badge>
            </span>

          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalPoints"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BBadge,
  BImgLazy,
  BMedia, BAvatar, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import vSelect from 'vue-select'
import Point from '@/mixins/classes/point'
import defineAbilityFor from '@/libs/acl/defineAbility'
import store from '@/store'
import { getPostStatusProperty, getUserAvatarText } from '@/mixins/functions'
import LeftFilterSidebar from './PointsListLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './usePointList'
import { usePointsUi } from '../usePoints'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BBadge,
    BImgLazy,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,

    // SFC
    LeftFilterSidebar,

    vSelect,
  },
  mixins: [getPostStatusProperty, getUserAvatarText],
  setup() {
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const {
      handleCartActionClick, toggleProductInWishlist, handleEditPointActionClick, resolvePointTypeBadgeVariant,
    } = usePointsUi()

    const {
      itemView, itemViewOptions, totalPoints,
    } = useShopUi()

    const user = store.getters['account/user']
    const ability = defineAbilityFor(user)

    const {
      points, fetchPoints,
    } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchPoints` which can be triggered initially and upon changes of filters
    const fetchFilteredPoints = () => {
      const args = {
        q: filters.value.q,
        sort_by: sortBy.value.value,
        page: filters.value.page,
        per_page: filters.value.perPage,
      }
      if (filters.value.pointPurposes.length !== 0) {
        args.purpose_ids = filters.value.pointPurposes.join()
      }

      if (filters.value.userId) {
        args.user_id = filters.value.userId
      }

      if (filters.value.postStatuses.length !== 0) {
        args.post_statuses = filters.value.postStatuses.join()
      }

      fetchPoints(args)
        .then(response => {
          const { total } = response.data.meta
          points.value = response.data.data.map($item => new Point($item))
          totalPoints.value = total
        })
    }

    fetchFilteredPoints()

    watch([filters, sortBy], () => {
      fetchFilteredPoints()
    }, {
      deep: true,
    })

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalPoints,
      toggleProductInWishlist,
      handleCartActionClick,
      handleEditPointActionClick,
      resolvePointTypeBadgeVariant,

      // useShopRemoteData
      points,
      ability,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.search-point.form-control{
  height: auto;
}
[dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
   padding-top: 0;
}
  .post-status{
    padding-left: 1em;
    padding-right: 1em;
    float: right;
  }

</style>
